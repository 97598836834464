import React, { useState } from "react";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { convertToCurrentTimeZone } from "../../../Utils/common";

const ChatTypeC = ({ element, onSelect }) => {
  const [startDate, setStartDate] = useState(new Date());
  const [isSelected, setIsSelected] = useState(false);
  const [selectedDate, setSelectedDate] = useState("");
  const [customProperties, setCustomProperties] = useState(null)
  return (
    <div className="second_chat">
      <div className="resultChat">
        <p>{element?.message}</p>
        <ReactDatePicker
          selected={startDate}
          disabled={isSelected}
          minDate={new Date()}

          popperClassName="custom-calenderPopper-dashboard"
          calendarClassName="custom-professionalDatePicker-dashboard"

          onChange={(date) => {
            if (!isSelected) {
              onSelect(convertToCurrentTimeZone(date.toISOString()), () => {
                setStartDate(date);
                setIsSelected(true);
                setCustomProperties({ minDate: date, maxDate: date })
              });
            }
          }}

          customInput={
            <button onClick={() => { }}>{startDate.toLocaleDateString()}</button>
          }
          inline
          {...customProperties}
        />
      </div>
      <div className="copyPath">
        <img src="/assets/Avatar_user.svg" alt="" />
        <span>&nbsp;</span>
      </div>
    </div>
  );
};

export default ChatTypeC;
