import React, { useEffect, useRef, useState } from "react";
import ChatTypeA from "../../Component/ChatBoxes/ChattypeA";
import ChatTypeB from "../../Component/ChatBoxes/ChattypeB";
import { useSelector } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import axiosInstance from "../../Utils/axios";
import ChatTypeC from "../../Component/ChatBoxes/ChattypeC";
import {
  convertUnixToHumanReadableDate,
  convertUnixToHumanReadableTime,
} from "../../Utils/common";
import { ToastContainer, toast } from "react-toastify";

const Index = () => {
  const { botInfo } = useSelector((state) => state.botinfo);
  const [isTaskOn, setIsTaskOn] = useState(false);
  const [inputVal, setInputVal] = useState("")
  const [Chats, setChats] = useState([]);
  const [Categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState({});
  const [inputStatus, setInputStatus] = useState(true);
  const [isMenu, setIsMenu] = useState(false);
  const [emailInput, setEmailInput] = useState("");
  const [firstAndLastName, setFirstNameLastName] = useState("");
  const [contact, setContact] = useState("");
  const [isFinal, setIsFinal] = useState(false);
  const [isFirstNameLastName, setIsFirstNameLastName] = useState(false);
  const [isContact, setIsContact] = useState(false);
  const [isEmail, setIsEmail] = useState(false);
  const [isEstimate, setIsEstimate] = useState(false);
  const [showSideLink, setShowSideLink] = useState(false)
  const menuContainerRef = useRef(null);
  const [saveCategoryId, setSaveCtegoryId] = useState("");
  const getBotChatData = () => {
    axiosInstance.get(`/major-categories/${botInfo?._id}`).then((res) => {
      setCategories([...res?.data]);
    });
  };


  const handleCommandInput = async (inputValue, chatsData) => {

    if (inputValue.includes("/portfolio")) {
      const webisites = ["https://meetoracle.com/", "https://askjefferson.com/", "https://dotbot.ai/", "https://helloadam.ai/", "https://askwhitefield.com/"]
      const randomNumber = Math.floor(Math.random() * 4);
      setChats([
        ...chatsData,
        {
          type: "message",
          data: `<p>Here is a recent A.I. project our team just completed:</p><p><a href=${webisites[randomNumber]}>${webisites[randomNumber]}</p>`,
          key: "Finale",
          website: webisites[randomNumber],
          isCommand: true
        },
      ]);
    }
    else if (inputValue.includes("/idea")) {
      axiosInstance.post("/market", {
        key: "idea"
      }).then(res => {
        setChats([
          ...chatsData,
          {
            type: "message",
            data: `${res.data}`,
            key: "Finale",
          },
        ]);
      })
    }
    else if (inputValue.includes("/estimate")) {
      if (inputValue.split(" ")[1]) {
        setIsEstimate(true);
        handlCustomInput(inputValue, true)
      }
      else {
        setIsEstimate(true);
        setChats([
          ...chatsData,
          {
            type: "message",
            data: `Please Provide a description of yout AI project`,
            key: "Finale",
          },
        ]);

      }
    }
    else {
      setChats([
        ...chatsData,
        {
          type: "message",
          data: "Please enter a valid command",
          key: "validCommand",
        },
      ]);
    }
  }

  const handlCustomInput = (inputValue, isEstimatePrm = false) => {
    const chatsData = [
      ...Chats,
      {
        type: "message",
        data: inputValue,
        key: "typedText",
        userType: "user",
      },
    ];
    setChats(chatsData);
    if (isFinal) {
      if (isEstimate || isEstimatePrm) {
        axiosInstance.post("/market", {
          key: "estimate",
          message: inputValue.replace("/estimate", "")
        }).then(res => {
          setChats([
            ...chatsData,
            {
              type: "message",
              data: `${res.data}`,
              key: "Finale",
            },
          ]);
          setIsEstimate(false)
        }).catch(err => {
          toast.error(err.message)
        })
      }
      else if (inputValue.split("")[0] === "/") {
        setIsTaskOn(true);
        handleCommandInput(inputValue, chatsData)
      }
      else {
        setChats([
          ...chatsData,
          {
            type: "message",
            data: "Please enter a valid command",
            key: "validCommand",
          },
        ]);
      }
    } else {

      if (isEmail) {
        if (
          /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(inputValue)
        ) {
          setIsTaskOn(true);
          setChats([
            ...chatsData,
            {
              type: "message",
              data: "What is your first and last name?",
              key: "firstAndLastName",
            },
          ]);
          setEmailInput(inputValue);
          setIsEmail(false);
          setIsFirstNameLastName(true);
        } else {
          setIsTaskOn(true);
          setChats([
            ...chatsData,
            {
              type: "message",
              data: "Please enter a valid email address",
              key: "email",
            },
          ]);
        }
      } else if (isFirstNameLastName) {
        if (/^[a-zA-Z ]{1,25}$/.test(inputValue)) {
          setIsTaskOn(true);
          setChats([
            ...chatsData,
            {
              type: "message",
              data: "What is a good phone number we can reach you at?",
              key: "phoneNumber",
            },
          ]);
          setFirstNameLastName(inputValue);
          setIsFirstNameLastName(false);
          setIsContact(true);
        }
        else {
          setChats([
            ...chatsData,
            {
              type: "message",
              data: "Enter a valid Name",
              key: "firstAndLastName",
            },
          ]);
        }
      } else if (isContact) {
        if (/^(?!0)\d{10}$/.test(inputValue)) {
          setChats([
            ...chatsData,
            {
              type: "calander",
              message:
                "Which one of the following days is best for you?",
              key: "calander",
            },
          ]);
          setIsContact(false);
          setContact(inputValue);
          setInputStatus(true);
        } else {
          setIsTaskOn(true);
          setChats([
            ...chatsData,
            {
              type: "message",
              data: "please enter a valid number",
              key: "phoneNumber",
            },
          ]);
        }
      } else {
        axiosInstance
          .post(`/chat-gpt/prompt`, {
            categoryId: saveCategoryId,
            prompt: inputValue,
          })
          .then((res) => {
            setInputStatus(!res.continueConversation);
            setIsTaskOn(true);
            setChats([
              ...chatsData,
              { type: "message", data: res.data, key: "typedTextResponse" },
            ]);
          })
          .catch((err) => console.log(err));
      }
    }
  };

  useEffect(() => {
    let chatArea = document.getElementById("chatArea");
    chatArea.scrollTop = chatArea.scrollHeight;
  }, [Chats]);

  useEffect(() => {
    if (botInfo?._id) {
      getBotChatData();
    }
  }, [botInfo]);

  useEffect(() => {
    if (botInfo?._id && Categories.length > 0) {
      const chatsData = [...Chats];
      if (chatsData.length === 0) {
        setIsTaskOn(true);
        chatsData.push({
          type: "message",
          data: botInfo?.landingMessage,
          key: "landingMessage",
        });
      }
      setChats(chatsData);
    }
  }, [botInfo, Categories]);


  useEffect(() => {

    document.getElementById("main").classList?.replace("fadeOut", "fadeIn");
  }, []);

  const comppleteCallback = (type) => {
    if (type === "landingMessage") {
      setIsTaskOn(true);
      setChats([
        ...Chats,
        {
          type: "message",
          data: botInfo?.additionalLandingMessage,
          key: "additionalLandingMessage",
        },
      ]);
    } else if (type === "additionalLandingMessage") {
      setShowSideLink(true)
      setChats([
        ...Chats,
        {
          type: "select",
          data: Categories,
          message: `So, tell me what kind of A.I. project you’re interested in building`,
          key: "category",
        },
      ]);
    } else if (type === "familiarText") {
      setInputStatus(false);
      document.getElementById("userInput").focus();
    } else if (
      (type === "typedTextResponse" || type === "unFamiliarText") &&
      inputStatus === true
    ) {
      setChats([
        ...Chats,
        {
          type: "select",
          message: `This is definitely something we can develop for you. There are so many questions our team members will have though and I do not want to leave them out of this conversation. Would you like to schedule a free consultation with our team of AI engineers?`,
          data: [{ name: "Yes" }, { name: "No" }],
          key: "consultation",
        },
      ]);
    }
  };

  const handleDateSelection = (selectedDate, callback) => {
    axiosInstance
      .get(`/scheduler/timings?date=${selectedDate}`)
      .then((res) => {
        const givenDates = res?.data;
        callback();
        setChats([
          ...Chats,
          {
            type: "select",
            data: givenDates.map((elements) => {
              return {
                value: elements,
                name: `${convertUnixToHumanReadableTime(
                  elements?.slotStartTime
                )} - ${convertUnixToHumanReadableTime(elements?.slotEndTime)}`,
              };
            }),
            message: `Which one of these slots works best for you?`,
            key: "timeSelect",
          },
        ]);
      })
      .catch((err) => {
        toast.error("No Slots Availabe");
      });
  };

  const onSelectCategory = (category, key) => {
    if (key === "category") {
      setSelectedCategory(category);
      setSaveCtegoryId(category?._id);
      setChats([
        ...Chats,
        {
          type: "select",
          message: category?.familiarityQuestion,
          data: [{ name: "Yes" }, { name: "No" }],
          key: "familiarityQuestion",
        },
      ]);
    } else if (key === "timeSelect") {
      axiosInstance
        .post("scheduler/timings", {
          email: emailInput,
          firstName: firstAndLastName.split(" ")[0],
          lastName: firstAndLastName.split(" ")[1],
          phone: contact,
          slot: category.value,
        })
        .then((res) => {
          setIsTaskOn(true);
          setInputStatus(false);
          setIsFinal(true);
          setChats([
            ...Chats,
            {
              type: "message",
              data: `<p>Your meeting has been confirmed for ${convertUnixToHumanReadableDate(
                category?.value?.slotStartTime
              )} at ${category?.name
                }.</p><p>I'm excited and I hope you are too. If there is anything else you need, feel free to ask me. You can use the following commands and I respond:</p><p style={{fontWeight : "700"}}>/portfolio - I will showcase a few AI projects we've recently completed.<p><p style={{fontWeight : "700"}}>/idea - I will give you an idea for the next great AI project.<p><p style={{fontWeight : "700"}}>/estimate - I will give you an unofficial estimate for your AI project, based on your description.<p>`,
              key: "noconsultationmsg",
            },
          ]);
        });
    } else if (key === "consultation") {
      if (category.name === "Yes") {
        setIsTaskOn(true);
        setChats([
          ...Chats,
          {
            type: "message",
            data: `What is your email address?`,
            key: "email",
          },
        ]);
        setIsEmail(true);
        setInputStatus(false);
        document.getElementById("userInput").focus();
      } else if (category.name === "No") {
        setChats([
          ...Chats,
          {
            type: "select",
            message: `Well, considering I do not have feelings - no hard feelings. Would you like to explore more?`,
            data: [{ name: "Yes" }, { name: "No" }],
            key: "noconsultation",
          },
        ]);
      }
    } else if (key === "noconsultation") {
      if (category.name === "Yes") {
        setIsTaskOn(true);
        setInputStatus(false);
        setIsFinal(true)
        setChats([
          ...Chats,
          {
            type: "message",
            data: `<p>If there is anything else you need, feel free to ask me. You can use the following command to explore:</p><p style={{fontWeight : "700"}}>/portfolio - I will showcase a few AI projects we’ve recently completed.<p><p style={{fontWeight : "700"}}>/idea - I will give you an idea for the next great AI project.<p><p style={{fontWeight : "700"}}>/estimate - I will give you an unofficial estimate for your AI project, based on your description.<p>`,
            key: "noconsultationmsg",
          },
        ]);
      } else if (category.name === "No") {
        setIsTaskOn(true);
        setInputStatus(false);
        setIsFinal(true)
        setChats([
          ...Chats,
          {
            type: "message",
            data: `<p>If there is anything else you need, feel free to ask me. You can use the following links to explore:</p><p style={{fontWeight : "700"}}>/portfolio- I will showcase a few AI projects we’ve recently completed.<p><p style={{fontWeight : "700"}}>/idea - I will give you an idea for the next great AI project.<p><p style={{fontWeight : "700"}} >/estimate - I will give you an unofficial estimate for your AI project, based on your description.<p>`,
            key: "noconsultationmsg",
          },
        ]);
      }
    } else {
      if (category.name === "Yes") {
        setIsTaskOn(true);
        setChats([
          ...Chats,
          {
            type: "message",
            data: selectedCategory?.familiarText,
            key: "familiarText",
          },
        ]);
      } else if (category.name === "No") {
        setIsTaskOn(true);
        setChats([
          ...Chats,
          {
            type: "message",
            data: selectedCategory?.unFamiliarText,
            key: "unFamiliarText",
          },
        ]);
      }
    }
  };


  const handleMenuItemCommand = (item) => {
    const chatsData = [
      ...Chats,
      {
        type: "message",
        data: item,
        key: "typedText",
        userType: "user",
      },
    ];
    setIsMenu(false)
    setChats(chatsData);
    handlCustomInput(item)
  }

  const handleClickOutside = (event) => {
    if (menuContainerRef.current && !menuContainerRef.current.contains(event.target)) {
      // Handle the click outside logic here  
      setIsMenu(false)
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
      <div id="main" className="homeWrap fadeOut">
        <div className="header headerIntro typingW">
          <a href="/">
            <img src="/assets/logo.svg" alt="" />
          </a>
          <a href="https://wedevelop.ai/" target="_blank" className="btn_img">
            {" "}
            <img src="/assets/btn_img.svg" className="btn" alt="" />
          </a>
        </div>

        <div className=" container-fluid cntr_main">
          <div className="typingModeSec">
            <div className="row">
              <div className="col-9 p-0">
                <div className="chatSection">
                  <div className="nameSec">
                    <h6>Chat with Niac</h6>
                    {/* <img src="/assets/eclipses.svg" alt="" /> */}
                  </div>

                  <div className="chatArea" id="chatArea">
                    {/* {botInfo} */}
                    {Chats.map((element, index) => {
                      return element.type === "message" ? (
                        <ChatTypeA
                          message={element?.data}
                          setIsTaskOn={setIsTaskOn}
                          isCommand={element?.isCommand}
                          website={element?.website}
                          handleComplete={() => {
                            setIsTaskOn(false);
                            comppleteCallback(element?.key);
                          }}
                          type={element?.userType}
                        />
                      ) : element.type === "calander" ? (
                        <ChatTypeC
                          element={element}
                          onSelect={(dateSelection, callback) =>
                            handleDateSelection(dateSelection, callback)
                          }
                        />
                      ) : (
                        <ChatTypeB
                          element={element}
                          onClick={(category) =>
                            onSelectCategory(category, element.key)
                          }
                        />
                      );
                    })}
                  </div>

                  <div className="inputArea">
                    {isFinal && <div id="MenuContainer" ref={menuContainerRef} className="btn-group dropup">
                      <button type="button" onClick={() => isFinal && !isTaskOn && setIsMenu(!isMenu)} className="drop-btn dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <img src="/assets/plus.svg" alt="" />
                      </button>
                      <div className={`dropdown-menu px-1 custom-Menu ${isMenu ? "d-block" : "d-none"}`} >
                        <div className="dropdown-item custom-menuitem" onClick={() => {
                          handleMenuItemCommand("/portfolio")
                        }}>/portfolio</div>
                        <div className="dropdown-item custom-menuitem" onClick={() => {
                          handleMenuItemCommand("/idea")
                        }}>/idea</div>
                        <div className="dropdown-item custom-menuitem" onClick={() => {
                          handleMenuItemCommand("/estimate")
                        }}>/estimate</div>
                      </div>

                    </div>}

                    <input
                      readOnly={inputStatus}
                      value={inputVal}
                      onChange={e => {
                        setInputVal(e.target.value)
                      }}
                      onKeyDown={(e) => {
                        if (!isTaskOn) {
                          if (e.key === "Enter" && e.target.value && e.target.value.trim()) {
                            handlCustomInput(e.target.value);
                            e.target.value = "";
                          }
                        }
                      }}
                      type="text"
                      placeholder='Type Here ...'
                      id="userInput"
                    />
                    <img src="/assets/noise.svg" alt="" />
                    <button onClick={(e) => { e.preventDefault(); if (inputVal) { setInputVal(""); handlCustomInput(inputVal) } }} className="senderBtn">
                      Send
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-3 p-0">
                <div className="rightsec">
                  <div className="rightsec_top">
                    <div className="social_btn">
                      {/* <img src="/assets/notification-box.svg" alt="" /> */}

                      <img src="/assets/online_user.svg" alt="" />
                      {/* <button>Export Chat</button> */}
                    </div>

                    <div className="impLink">
                      <p>Niac’s Important Links</p>
                    </div>

                    {showSideLink && <a href="https://cheetahagency.com/about/"> <div className="ourStory">
                      <p className="text">
                        The Cheetah Story
                      </p>
                      <a href="https://cheetahagency.com/about/">
                        https://cheetahagency.com/about/
                      </a>
                      <div className="userIcon">
                        <img src="/assets/uIcon.svg" alt="" />
                        <p>Just Now</p>
                      </div>
                    </div> </a>}
                  </div>
                  <div className="ftr_btn">
                    <button
                      onClick={() =>
                        window.open(
                          "https://dotbot.ai/",
                          "_blank"
                        )
                      }
                    >
                      {" "}
                      Learn more about DotBot
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Index;
