import React from "react";
import Onboardimagecontainer from "../Onboardimage";
import TypeText from "../TypeText";
import { useSelector } from "react-redux";

const BotLoader = () => {
  const { botInfo } = useSelector((state) => state.botinfo);

  return (
    <div className="cntr_wrap_text">
      <Onboardimagecontainer />
      <div className="cntr_wrap_colm">
        <div class="lds-grid">
          <div>
            <img src="/assets/ellipse.svg" />{" "}
          </div>
          <div>
            <img src="/assets/ellipse.svg" />
          </div>
          <div>
            <img src="/assets/ellipse.svg" />
          </div>
        </div>

        {botInfo?.alias && <TypeText
          renderText={`<p>${botInfo?.alias} ${botInfo?.generalVerb}</p>`}
        ></TypeText>}
        {/* <p className="main_font_size">Niac is thinking...</p> */}
      </div>
    </div>
  );
};

export default BotLoader;
