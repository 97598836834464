import React, { useEffect, useState } from "react";
import TypeText from "../../TypeText";
import { getURLMetaData } from "../../../Utils/common";

const ChatTypeA = ({ message, handleComplete, setIsTaskOn, type = "bot", isCommand = false, website = "" }) => {
  const [MetaData, setMetaData] = useState(null);
  useEffect(() => {
    if (isCommand && website) {
      getURLMetaData(website).then((res) => setMetaData(res)).catch((err) => {
        console.log(err)
      })
    }
  }, [])
  return (
    <div className={`first_chat ${type}`}>
      <div className="resultChat word-break-all">
        {type === "user" ? <>{message}</> : <TypeText
          renderText={`
       ${message}
      `}
          handleComplete={handleComplete}
          TypeDelay={2}
        />}
        {isCommand && MetaData?.images && MetaData?.images.length > 0 && website && <a href={MetaData?.url}>
          <img className="preview-image" src={MetaData?.images[0]} alt="website" />
        </a>}
      </div>
      <div className={`copyPath ${type}-chat`}>
        <img
          src={
            type === "user"
              ? "/assets/Avatar_owner.svg"
              : "/assets/Avatar_user.svg"
          }
          alt=""
        />
        <span>&nbsp;</span>
      </div>
    </div>
  );
};

export default ChatTypeA;
