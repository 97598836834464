import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  botInfo: null,
};

export const botInfoSlice = createSlice({
  name: "botInfo",
  initialState,
  reducers: {
    setBotInfo: (state, actions) => {
      state.botInfo = actions.payload;
    },
  },
});

export const { setBotInfo } = botInfoSlice.actions;
export default botInfoSlice.reducer;
