import React, { useEffect, useState } from "react";
import Onboardimagecontainer from "../../Component/Onboardimage";
import Header from "../../Component/Header";
import TypeText from "../../Component/TypeText";
import BotLoader from "../../Component/BotLoader";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
const Services = () => {
  const [loader, setLoader] = useState(true);
  const navigate = useNavigate();
  const [isTyped, setIsTyped] = useState(false);
  const { botInfo } = useSelector((state) => state.botinfo);
  useEffect(() => {
    setTimeout(() => {
      setLoader(false);
    }, 3000);
  }, []);
  return (
    <>
      {loader ? (
        <>
          {" "}
          <div className="homeWrap">
            <Header />
            <div className="homeSub_wrap">
              <div>
                <BotLoader />

                <div className="leftImg">
                  <img src="/assets/img2.svg" alt="" />
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="homeWrap">
            <Header />
            <div className="homeSub_wrap">
              <div>
                <div className="cntr_wrap_text">
                  <Onboardimagecontainer />
                  <div className="cntr_wrap_colm cntr_wrap_colm3">
                    {/* <img src="/assets/colorEclipes.svg" alt="" /> */}
                    <TypeText
                      renderText={`<p>
                      ${botInfo?.applicationStartUpMessages[1]?.text}
                      </p>`}
                      handleComplete={() => setIsTyped(true)}
                    ></TypeText>

                    {/* <div className="btn_div"> */}
                    {isTyped && (
                      <>
                        {botInfo?.applicationStartUpMessages[1]?.buttons?.map(
                          (element, index) => {
                            return (
                              <button
                                key={index}
                                onClick={() => index === 0 ?  navigate("/tointeractice") : window.open("https://wedevelop.ai/", "_blank")}
                                className={index === 0 ? "btn1" : "btn2"}
                              >
                                {element?.text}
                              </button>
                            );
                          }
                        )}{" "}
                      </>
                    )}
                    {/* </div> */}
                  </div>
                </div>

                <div className="leftImg">
                  <img src="/assets/img2.svg" alt="" />
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Services;
