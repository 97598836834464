import React, { useState } from "react";
import Onboardimagecontainer from "../../Component/Onboardimage";
import TypeText from "../../Component/TypeText";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
const Websiteinfo = () => {
  // const [isTyped, setIsTyped] = useState(false);
  const navigate = useNavigate();
  const { botInfo } = useSelector((state) => state.botinfo);
  return (
    <>
      <div id="main" className="    homeWrap">
        <div className="header headerIntro">
          <a href="/">
            <img src="/assets/logo.svg" alt="" />
          </a>
          {/* <button>View Our 2D Website</button> */}

          <a href="https://wedevelop.ai/" target="_blank">
            {" "}
            <img src="/assets/btn_img.svg" className="cursor-pointer" alt="" />
          </a>
        </div>
        <div className="  homeSub_wrap">
          <div>
            <div className="cntr_wrap_text">
              <Onboardimagecontainer />
              <div className="cntr_wrap_colm cntr_wrap_colm3">
                {/* <img src="/assets/colorEclipes.svg" alt="" /> */}
                <TypeText
                  handleComplete={() => {
                    document.getElementById("main").classList.add("fadeOut");
                    setTimeout(() => {
                      navigate("/chat");
                    }, 2000);
                  }}
                  renderText={`<p>
                  ${botInfo?.applicationStartUpMessages[3]?.text}
                </p>
                `}
                />
              </div>
            </div>

            <div className="leftImg">
              <img src="/assets/img2.svg" alt="" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Websiteinfo;
