import React from "react";

const Onboardimagecontainer = () => {
  return (
    <div className="cntr_wrap_img">
      <img src="/assets/img1.jpg" className="img_main_mobile" alt="" />
      <img src="/assets/Group59.jpg" className="img_main" alt="" />
      <span>
        <img src="/assets/Group14.png" className="img_main1" alt="" />
        <img src="/assets/Group61.png" className="img_main_overlay" alt="" />
      </span>
      <img src="/assets/Group58.png" className="img_main2" alt="" />
      <img src="/assets/Rectangle16.png" className="img_main3" alt="" />
    </div>
  );
};

export default Onboardimagecontainer;
