import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Services from "./Pages/Navigate";
import ToInteractice from "./Pages/ToInteractive/index";
import { useDispatch } from "react-redux";
import 'animate.css';
import TypingMode from "./Pages/TypingMode/index";
import Home from "./Pages/Home";
import "react-toastify/dist/ReactToastify.css";
import Websiteinfo from "./Pages/WebSiteInfo";
// import BotContinue from "./Pages/Introduction";
import { useEffect } from "react";
import axiosInstance from "./Utils/axios";
import { setBotInfo } from "./store/common/botInfo";
function App() {
  const dispatch = useDispatch();
  const getBotInfo = () => {
    axiosInstance
      .get("/init-app/Niac")
      .then((res) => {
        localStorage.setItem("BOT-INFO", JSON.stringify(res?.data));
        dispatch(setBotInfo(res?.data));
      })
      .catch((err) => {
        console.log(err.message);
      });
  };
  useEffect(() => {
    getBotInfo();
  }, []);
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/services" element={<Services />} />
          <Route path="/websiteinfo" element={<Websiteinfo />} />
          <Route path="/tointeractice" element={<ToInteractice />} />
          <Route path="/chat" element={<TypingMode />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
