import axios from "axios";
import { toast } from "react-toastify";
import { projecturl } from "./Constants";

console.log(process.env);
const axiosInstance = axios.create({
  baseURL: projecturl,
});

axiosInstance.interceptors.request.use(
  function (config) {
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  function (response) {
    return response.data;
  },
  (error) => {
    console.log(error);
    toast.error(` ${error?.response?.data?.message}`, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
    return Promise.reject(error);
  }
);

export default axiosInstance;
