import axios from "axios";

export function convertToCurrentTimeZone(isoString) {
  const utcTimestamp = Date.parse(isoString);
  const unixTimestamp = Math.floor(utcTimestamp / 1000); // Convert milliseconds to seconds
  return unixTimestamp;
}

export function convertUnixToHumanReadableTime(unixdate, timezoneroute) {
  try {
    let timezone = "Asia/Kolkata";
    if (timezoneroute) {
      if (timezoneroute === "Asia/Calcutta") {
        timezone = "Asia/Kolkata";
      }
    } else if (typeof window !== "undefined") {
      timezone = JSON.parse(localStorage.getItem("userInfo"))?.timezone;
      if (timezone === "Asia/Calcutta") {
        timezone = "Asia/Kolkata";
      }
    }
    const date = new Date(unixdate * 1000);
    const options = { timeZone: timezone };
    const formattedDate = date.toLocaleString("en-US", options);
    const dateObj = new Date(formattedDate);
    var hour = dateObj.getHours();
    var min = dateObj.getMinutes();
    const formattime = timeConvert(
      addLeadingZero(hour) + ":" + addLeadingZero(min)
    );
    var time = formattime;
    return time;
  } catch (error) {
    return error.message;
  }
}

/**
 *
 * @param {Take unix date as an arguement of function} unixdate
 * @returns date in 13 july 2013, 11:00 AM format with the user timeStamp
 */

export function convertUnixToHumanReadable(unixdate) {
  try {
    let timezone = "Asia/Kolkata";
    if (typeof window !== "undefined") {
      timezone = JSON.parse(localStorage.getItem("userInfo")).timezone;
      if (timezone === "Asia/Calcutta") {
        timezone = "Asia/Kolkata";
      }
    }
    const date = new Date(unixdate * 1000);
    const options = { timeZone: timezone };
    const formattedDate = date.toLocaleString("en-US", options);
    const dateObj = new Date(formattedDate);
    var months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    var year = dateObj.getFullYear();
    var month = months[dateObj.getMonth()];
    var day = dateObj.getDate();
    var hour = dateObj.getHours();
    var min = dateObj.getMinutes();
    const formattime = timeConvert(
      addLeadingZero(hour) + ":" + addLeadingZero(min)
    );
    var time = month + " " + day + ", " + year + " " + formattime;
    return time;
  } catch (error) {
    return error.message;
  }
}

export function timeConvert(time) {
  // Check correct time format and split into components
  time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [
    time,
  ];

  if (time.length > 1) {
    // If time format correct
    time = time.slice(1); // Remove full string match value
    time[5] = +time[0] < 12 ? " AM" : " PM"; // Set AM/PM
    time[0] = +time[0] % 12 || 12; // Adjust hours
  }
  return time.join(""); // return adjusted time or original string
}

//this function will add 0 before every one digit number as Date convertion gives 1:9 Am
//this will convert it to 01:00 Am
export function addLeadingZero(num) {
  if (parseInt(num) < 10) {
    return "0" + num;
  }
  return num;
}

/**
 *
 * @param {Take unix date as an arguement of function} unixdate
 * @returns date in 11 Jan 2023 format with the user timeStamp
 */

export function convertUnixToHumanReadableDate(unixdate, timezoneroute) {
  try {
    let timezone = "Asia/Kolkata";
    if (timezoneroute) {
      if (timezoneroute === "Asia/Calcutta") {
        timezone = "Asia/Kolkata";
      }
    } else if (typeof window !== "undefined") {
      timezone = JSON.parse(localStorage.getItem("userInfo"))?.timezone;

      if (timezone === "Asia/Calcutta") {
        timezone = "Asia/Kolkata";
      }
    }
    const date = new Date(unixdate * 1000);
    const options = { timeZone: timezone };
    const formattedDate = date.toLocaleString("en-US", options);
    const dateObj = new Date(formattedDate);
    var months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    var year = dateObj.getFullYear();
    var month = months[dateObj.getMonth()];
    var day = dateObj.getDate();
    var time = month + " " + day + ", " + year;
    return time;
  } catch (error) {
    return error.message;
  }
}


export const getURLMetaData = async (website) => {
  const MetaData = await axios.get(`https://jsonlink.io/api/extract?url=${encodeURIComponent(website)}`)
  return MetaData.data
}