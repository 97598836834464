import React from "react";
import Typed from "typed.js";

const TypeText = ({ renderText, handleComplete, TypeDelay = 20 }) => {
  const el = React.useRef(null);

  React.useEffect(() => {
    const typed = new Typed(el.current, {
      strings: [renderText],
      typeSpeed: 30,
      onComplete: handleComplete
        ? handleComplete
        : () => {
          return;
        },
      cursorChar: "",
    });

    return () => {
      // Destroy Typed instance during cleanup to stop animation
      typed.destroy();
    };
  }, [renderText]);
  return <span ref={el} />;
};

export default TypeText;
