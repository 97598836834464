import React, { useEffect, useState } from "react";
import Onboardimagecontainer from "../../Component/Onboardimage";
import TypeText from "../../Component/TypeText";
import { useNavigate } from "react-router-dom";
import BotLoader from "../../Component/BotLoader";
import Header from "../../Component/Header";
import { useSelector } from "react-redux";

const Home = () => {
  const navigate = useNavigate();
  const [loader, setLoader] = useState(true);

  const { botInfo } = useSelector((state) => state.botinfo);
  useEffect(() => {
    setTimeout(() => {
      setLoader(false);
    }, 3000);
  }, []);

  return (
    <>
      {loader ? (
        <>
          <div className="homeWrap">
            <Header />
            <div className="homeSub_wrap">
              <div>
                <BotLoader />

                <div className="leftImg">
                  <img src="/assets/img2.svg" alt="" />
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="homeWrap">
            <Header />
            <div className="homeSub_wrap">
              <div
                className="cursorPointer"
              // onClick={() => {
              //   if (isTyped) {
              //     navigate("/services");
              //   }
              // }}
              >
                <div className="cntr_wrap_text">
                  <Onboardimagecontainer />
                  <div className="cntr_wrap_colm cntr_wrap_colm2">
                    {botInfo?.applicationStartUpMessages[0]?.text && <TypeText
                      renderText={`<p>${botInfo?.applicationStartUpMessages[0]?.text}</p>`}
                      handleComplete={() =>
                        setTimeout(() => {
                          navigate("/services");
                        }, 2000)
                      }
                    ></TypeText>}
                  </div>
                </div>

                <div className="leftImg">
                  <img src="/assets/img2.svg" alt="" />
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Home;
