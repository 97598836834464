import React, { useState } from "react";

const ChatTypeB = ({ element, onClick }) => {
  const [active, setActive] = useState("");

  return (
    <div className="second_chat">
      <div className="resultChat">
        <p>{element?.message}</p>

        {element.data.map((e) => {
          return (
            <>
              <div
                onClick={() => {
                  if (!active) {
                    onClick(e);
                    setActive(e.name);
                  }
                }}
                className={`topics ${active === e.name && "active"}`}
                style={{ border: e.border }}
              >
                {e.name}
              </div>
            </>
          );
        })}
      </div>
      <div className="copyPath">
        <img src="/assets/Avatar_user.svg" alt="" />
        <span>&nbsp;</span>
      </div>
    </div>
  );
};

export default ChatTypeB;
